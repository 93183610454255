import { Box, Divider } from '@chakra-ui/react'
import { AppBar, Hidden, IconButton, useScrollTrigger } from '@material-ui/core'
import { Link as SiteLink } from 'common/components/atoms/Link'
import { SecondaryNavigation } from 'common/components/molecules/SecondaryNavigation'
import { SiteSearch } from 'common/components/organisms/SiteSearch'
import { useWindowSize } from 'common/hooks/useWindowSize'
import { PageItem } from 'common/utils/getSiteSearchData'
import _ from 'lodash'
import React, { useState } from 'react'
import TagManager from 'react-gtm-module'
import { JumbotronProps } from '../../Jumbotron'
import { ReactComponent as ChatIcon } from '../Chat.svg'
import { GetAdviceButton } from '../GetAdviceButton'
import { Navigation, NavigationItem } from '../interfaces'
import { NavbarMobileMenu } from '../NavbarMobile/NavbarMobileMenu'
import { ReactComponent as IconClosedDesktop } from './iconClosedDesktop.svg'
import Login from './Login'

export interface MainNavigationProps {
    login: any
    navigation: Navigation
    jumbotronVariant?: JumbotronProps['variant']
    siteSearchData: PageItem[]
}

export const MainNavigation: React.FC<MainNavigationProps> = ({
    login,
    navigation,
    siteSearchData,
}) => {
    const windowSize = useWindowSize()
    const trigger = useScrollTrigger({
        threshold: 200,
        disableHysteresis: true,
    })
    const height =
        windowSize[0] > 1024
            ? 79
            : windowSize[0] >= 768 && windowSize[0] <= 1024
            ? 62
            : 53
    const textStyles = {
        font: '16px Assistant, Helvetica, sans-serif',
        fontWeight: '500',
        color: '#5261ac',
        textDecoration: 'none',
        /* Make the link cover the entire list item-container */
        display: 'block',
        lineHeight: '1.54',
    }

    const firstLevelMenuStyles = {
        listStyle: 'none',
        padding: '30px 0 0 0',
        margin: 0,
        display: 'flex',
        '> span': {
            position: 'relative',
            marginRight: '40px',
            float: 'left',
            height: '49px',
            width: 'max-content',
            background: '#ffffff',
            ':hover': {
                '> div': {
                    display: 'inline',
                },
            },
        },
        p: textStyles,
        a: textStyles,
    }

    const secondLevelMenuStyles = {
        position: 'absolute',
        top: '50px',
        left: '0',
        width: '150px',
        listStyle: 'none',
        padding: '0',
        margin: '0',
        display: 'none',
        zIndex: '1000',
        '> span': {
            display: 'flex',
            padding: '16px 24px 16px 24px',
            position: 'relative',
            minHeight: '50px',
            alignItems: 'center',
            background: '#ffffff',
            borderBottom: '1px solid #c1c7e1',
            width: '258px',
            filter: 'drop-shadow(rgba(58, 49, 96, 0.18) 0px 4px 10px)',
            ':hover': {
                backgroundColor: '#ebedf7',
                '> div': {
                    display: 'inline-block',
                },
            },
        },
        '> span:last-child': {
            borderBottom: 'none',
        },
        p: textStyles,
        a: textStyles,
    }

    const thirdLevelMenuStyles = {
        position: 'absolute',
        top: '0',
        right: '-150px',
        width: '150px',
        lisStyle: 'none',
        padding: '0',
        margin: '0',
        display: 'none',
        '> span': {
            display: 'flex',
            padding: '16px 24px 16px 24px',
            alignItems: 'center',
            minHeight: '50px',
            width: '258px',
            background: '#ebedf7',
            borderBottom: '1px solid #c1c7e1',
            filter: 'drop-shadow(rgba(58, 49, 96, 0.18) 0px 4px 10px)',
            ':hover': {
                backgroundColor: '#f1f3fd',
            },
        },
    }

    const fireAnalyticsEvent = (navigationData: NavigationItem) => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'navigationClick',
                clickText: navigationData.name,
                clickURL: navigationData.url || '',
                clickID: navigationData.name,
            },
        })
    }

    const [menu, setMenu] = useState(false)
    return (
        <Box as='nav'>
            <AppBar
                color={'inherit'}
                data-testid='MainNavigation'
                elevation={0}
                style={{
                    zIndex: 30,
                    background: '#fff',
                    height: height + 48,
                    transition: 'filter 1.5s ease, background 0.5s ease',
                    filter: trigger
                        ? 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.08))'
                        : undefined,
                }}
            >
                <SecondaryNavigation />
                <Box
                    height={height}
                    alignItems='center'
                    display='flex'
                    flex={1}
                >
                    <Box flex={1} />
                    <Hidden lgDown>
                        {/* First Level Menu */}
                        <Box sx={firstLevelMenuStyles}>
                            {_.map(
                                navigation,
                                (navItem: NavigationItem, key) => {
                                    // If there are no subpages, render a link
                                    if (!navItem.pages)
                                        return (
                                            <span key={key}>
                                                <SiteLink
                                                    path={navItem.url}
                                                    onClick={() =>
                                                        fireAnalyticsEvent(
                                                            navItem
                                                        )
                                                    }
                                                >
                                                    {navItem.name}
                                                </SiteLink>
                                            </span>
                                        )
                                    // If there are sub pages, map over them and render lvl 2 menu
                                    return (
                                        <span key={key}>
                                            <p>{navItem.name}</p>
                                            <Box sx={secondLevelMenuStyles}>
                                                {_.map(
                                                    navItem.pages,
                                                    (item: any, key) => {
                                                        // If there are no sub pages render a link
                                                        if (!item.pages)
                                                            return (
                                                                <span key={key}>
                                                                    <SiteLink
                                                                        path={
                                                                            item.url
                                                                        }
                                                                        onClick={() =>
                                                                            fireAnalyticsEvent(
                                                                                item
                                                                            )
                                                                        }
                                                                    >
                                                                        <Box
                                                                            alignItems='center'
                                                                            display='flex'
                                                                            flexDirection='row'
                                                                        >
                                                                            {item.featured && (
                                                                                <Box
                                                                                    mr={
                                                                                        2
                                                                                    }
                                                                                >
                                                                                    <ChatIcon />
                                                                                </Box>
                                                                            )}
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </Box>
                                                                    </SiteLink>
                                                                </span>
                                                            )
                                                        // If there are subpages, map over them and render lvl 3 menu
                                                        return (
                                                            <span key={key}>
                                                                <p>
                                                                    {item.name}
                                                                </p>
                                                                <Box
                                                                    sx={
                                                                        thirdLevelMenuStyles
                                                                    }
                                                                >
                                                                    {_.map(
                                                                        item.pages,
                                                                        (
                                                                            page: any,
                                                                            key
                                                                        ) => {
                                                                            return (
                                                                                <span
                                                                                    key={
                                                                                        key
                                                                                    }
                                                                                >
                                                                                    <SiteLink
                                                                                        path={
                                                                                            page.url
                                                                                        }
                                                                                        onClick={() =>
                                                                                            fireAnalyticsEvent(
                                                                                                page
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            page.name
                                                                                        }
                                                                                    </SiteLink>
                                                                                </span>
                                                                            )
                                                                        }
                                                                    )}
                                                                </Box>
                                                            </span>
                                                        )
                                                    }
                                                )}
                                            </Box>
                                        </span>
                                    )
                                }
                            )}
                        </Box>
                        <SiteSearch siteSearchData={siteSearchData} />
                        <Divider
                            h={8}
                            ml={2}
                            mr={4}
                            orientation='vertical'
                            borderColor='#C1C7E1'
                            borderWidth='0.5px'
                        />
                    </Hidden>
                    <Hidden smDown>
                        <GetAdviceButton />
                    </Hidden>
                    <Login login={login} colorLabelMobile='#5E6CDA' showArrowMobile={true} fontWeightMobile={600} marginRightMobile='20px'  />                    
                    <Hidden smDown>
                        <Box mr={[4, 4, 4, 4, 12]} />
                    </Hidden>
                    <Hidden xlUp>
                        <IconButton
                            aria-label='show menu'
                            onClick={() => setMenu(true)}
                        >
                            <IconClosedDesktop />
                        </IconButton>
                        <Box mr={4} />
                        <NavbarMobileMenu
                            open={menu}
                            login={login}
                            navigation={navigation}
                            setOpen={setMenu}
                            siteSearchData={siteSearchData}
                        />
                    </Hidden>
                </Box>
            </AppBar>
        </Box>
    )
}
